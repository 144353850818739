import { Link, graphql, useStaticQuery } from "gatsby";
// import { Link as LinkSpy } from "react-scroll";
import React, { useEffect, useState, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import PropTypes from "prop-types";
import Logo from "../../../assets/images/victory_point_logo.svg";
import { Button, IconButton, Container } from "../../Shared";
import { CloseIcon, MenuIcon, Telegram, Twitter } from "../../Icons";

const query = graphql`
  {
    menu: allMenuItemsJson {
      nodes {
        id
        href
        title
      }
    }
  }
`;

function NavBar() {
  const data = useStaticQuery(query);
  const menu = data?.menu?.nodes;

  const [isOpen, setIsOpen] = useState(false);

  const [isScrolling, setIsScrolling] = useState(false);

  const handleScroll = () => {
    if (window.scrollY >= 66) {
      setIsScrolling(true);
    } else {
      setIsScrolling(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener(handleScroll);
    };
  }, []);

  return (
    <>
      <header
        className={`fixed top-0 left-0 right-0 z-10 bg-transparent transition ease-in-out duration-300 ${
          isScrolling && "backdrop-blur-lg bg-main/50 shadow-xl"
        }`}
      >
        <Container>
          <div className="flex justify-between items-center py-6">
            <Link to="/" className="flex-shrink-0">
              <img src={Logo} alt="victory point" className="w-56" />
            </Link>
            <nav className="hidden xl:block">
              <ul className="flex">
                {menu
                  .filter(
                    ({ title }) =>
                      !["v-team", "v-friends", "v-roadmap"].includes(title)
                  )
                  .map((menuItem) => (
                    <li
                      className="capitalize font-medium text-base px-4"
                      key={menuItem.id}
                    >
                      <Link
                        to={`${menuItem.href}`}
                        className="font-serif text-[.65rem] cursor-pointer hover:text-red-500"
                        activeClassName="text-red-500"
                      >
                        {menuItem.title}
                      </Link>
                    </li>
                  ))}
              </ul>
            </nav>
            <Button
              size="sm"
              variant="outline"
              className="hidden xl:inline-flex"
              onClick={() =>
                window.open(
                  "https://www.canva.com/design/DAFPgrvNA8U/3I1Lc5Vwq3ZPPqeGY_bzCQ/view?utm_content=DAFPgrvNA8U&utm_campaign=designshare&utm_medium=link&utm_source=viewer&fbclid=IwAR1VgtkKulPsQQuBa7opYdXr4zbl9EIdnabjkmeknwejS-bDQkx0Dr3yt9M",
                  "_blank"
                )
              }
            >
              PITCH DECK
            </Button>
            <IconButton
              size="sm"
              className="xl:hidden"
              icon={<MenuIcon />}
              onClick={() => setIsOpen(true)}
            />
          </div>
        </Container>
      </header>
      <MobileMenu
        isOpen={isOpen}
        closeModal={() => setIsOpen(false)}
        menu={menu}
      />
    </>
  );
}

export default NavBar;

function MobileMenu({ isOpen, closeModal, menu }) {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={closeModal}
      >
        <div className="min-h-screen px-4">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-main/75 backdrop-blur" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="fixed inset-0 bg-main/75">
              <Container className="h-full">
                <div className="flex flex-col justify-between h-full py-8">
                  <div className="flex justify-between items-center">
                    <Link to="/" className="flex-shrink-0">
                      <img src={Logo} alt="victory point" className="w-56" />
                    </Link>
                    <IconButton
                      variant="outline"
                      icon={<CloseIcon />}
                      onClick={closeModal}
                    />
                  </div>
                  <ul className="text-center">
                    {menu.map((menuItem) => (
                      <li className="py-4" key={menuItem.id}>
                        <Link
                          to={`${menuItem.href}`}
                          onClick={() => closeModal()}
                          className="font-serif text-[.65rem] cursor-pointer hover:text-red-500"
                          activeClassName="text-red-500"
                        >
                          {menuItem.title}
                        </Link>
                      </li>
                    ))}
                  </ul>
                  <div className="flex justify-center space-x-6">
                    <a
                      href="https://twitter.com/VictoryPoint_io"
                      target="_blank"
                      className="flex space-x-2 text-sm text-white/60 hover:text-white capitalize transition-all ease-out duration-300"
                      rel="noreferrer"
                    >
                      <Twitter className="w-4" />
                      <span>Twitter</span>
                    </a>
                    <a
                      href="https://t.me/+uPhVrPJYg85lNmQ0"
                      target="_blank"
                      className="flex space-x-2 text-sm text-white/60 hover:text-white capitalize transition-all ease-out duration-300"
                      rel="noreferrer"
                    >
                      <Telegram className="w-4" />
                      <span>Telegram</span>
                    </a>
                  </div>
                </div>
              </Container>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}

MobileMenu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  menu: PropTypes.arrayOf(PropTypes.object).isRequired,
};
